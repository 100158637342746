import React from 'react'

import styles from "./policies-css-module.module.scss"
import '../styles.scss'

import { Layout } from '../components'

//import { Link } from "gatsby"
import ReturnShipmentPolicy from '../components/policies/returnShipmentPolicy/return-shipment-policy'

const ReturnShipPolicy = () => {
	return (
		<>
			<Layout>
					<div className={styles.container_rs}>
						{/* <div className={styles.link_section}>
							<div className={styles.link_section_div}>
								<Link exact className={styles.tc_links} activeClassName='is-active' to='/return-shipment-policy/'>RETURN SHIPMENT POLICY</Link>
							</div>
						</div> */}
						<div className={styles.content_section_rs}>
								<ReturnShipmentPolicy />
						</div>
					</div>
			</Layout>
		</>
	)
}

export default ReturnShipPolicy
