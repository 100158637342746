
import React from 'react'

import './return-shipment-policy.scss'

const ReturnShipmentPolicy = () => {
    return (
        <>
            <div className='main-content'>
                <div className='title'>Return Shipments Policy @ Solv</div>
                <p>This Return Shipments Policy (<strong>&quot;Policy&quot;</strong>) governs the return request raised by the Buyer in accordance with the terms of this Policy (&quot;<strong>Return Request</strong>&quot;) and the mechanism for resolution of disputes raised by the Seller in relation to the Return Requests.</p>
                <p>Any capitalized terms used herein this Policy and not defined explicitly shall have the same meaning as defined in the Terms of Use, as amended from time to time.</p>
                <p>The   Users   understand   that   Standard   Chartered   Research   and   Technology   India   Private   Limited (hereinafter referred to as 'we' or “Solv”) is an intermediary platform and shall only mediate the Return Request. Except for any logistics related issues (where logistics services have been availed from Solv), it shall be sole responsibility of the Seller to resolve the issues/ concerns raised by the Buyer pertaining to the Return Request.</p>
                <p>We shall not assume any liability for any failure on the part of the Seller to resolve the Return Request raised by the Buyer.</p>
                <p>We may modify this Policy from time to time, and any such changes will be reflected on the Platform and be effective immediately upon the changes being reflected on the Platform. Users agree to be bound to any such changes or modifications and understand and accept the importance of regularly reviewing the Policy as updated on the Platform from time to time.</p>
                <p><strong>A. RETURN REQUEST</strong></p>
                <ol>
                    <li>
                        Buyer  understands  that  Solv  is  an  intermediary  platform  and  only  be  responsible  to  mediate  the  return request  raised  by  Buyer  with  the  Seller.  It  shall  be  sole  responsibility  of  the  Seller  to  resolve  the  issues/ concerns raised by Buyer in your return request. We shall not assume any liability for any failure on the part of the Seller to resolve Buyer’s issue.
                    </li>
                    <li>
                        Buyer may raise a return request through any of the following channels&nbsp;
                        <ol>
                            <li>Raise a return request on the Solv App&nbsp;</li>
                            <li>Chat with live agent on Solv App via Help section in the main menu&nbsp;</li>
                            <li>
                                <a className="mail-anchor" href="mailto:cs@solvezy.com">
                                    Write to <span style={{ "textDecoration": "underline" }}>cs@solvezy.com</span> &nbsp;
                                </a>
                            </li>
                            <li>Call on customer care number 9108191081&nbsp;</li>
                            <li>Send a WhatsApp message on customer care number 9108191081&nbsp;</li>
                        </ol>
                    </li>
                    <li>
                        Buyer may raise a Return Request on the Platform for any one of the following reasons:
                        <ol>
                            <li>Product(s) is physically damaged or is defective, including damage to the inner box delivered.&nbsp;</li>
                            <li>Warranty issues or quality related issues with respect to the Product delivered.</li>
                            <li>Wrong Product or the Product is not matching the description or specifications mentioned on the listing page on the Platform.</li>
                            <li>Part of the Order/ Products is found to be missing/mismatch due to reasons attributable to  the  Seller  in  case  products  delivered  in  intact  packaging  or  where  packaging  has  not been tempered.</li>
                        </ol>
                    </li>
                    <p>&nbsp;</p>
                    <p>Without  prejudice  to  the  foregoing,  Return  Request  may  be  raised  for  the  following  reasons (<strong>&quot;Logistics Related Issues&quot;</strong>):</p>
                    <p>&nbsp;</p>
                    <ol>
                        <li>Part of the Order/ Products are found to be missing due to any reasons attributable to the logistics; or&nbsp;</li>
                        <li>Product was damaged in-transit; or&nbsp;</li>
                        <li>Incorrect boxes delivered; or&nbsp;</li>
                        <li>Any other logistics related issues.&nbsp;</li>
                    </ol>
                    <p>&nbsp;</p>
                    <p><strong>Note:</strong>  The  Buyer  not  to  accept  delivery  in  case  of  logistics  partner  is  delivering  open/damaged box.</p>
                    <p>&nbsp;</p>
                    <li>
                        At  the  time  of  making  a  return  request  on  the  platform,  you  will  be  required  to  provide  appropriate supporting documentations/ proofs including without limitation:
                        <ol>
                            <li>Required  evidences  as  mentioned  in  this  return  policy  shall  be  shared  immediately  or  maximum next day post initiating the return request, failing to the same, the return request can be rejected.</li>
                            <li>Images of the product(s) indicating the issue in the product/ shipment delivered. The images need to capture the following:
                                <ol style={{ "listStyleType": "lower-alpha" }}>
                                    <li>Shipping label with order ID;</li>
                                    <li>Original Delivered Shipment</li>
                                    <li>issue observed by the buyer in the product;</li>
                                    <li>damaged product; and or</li>
                                    <li>the IMEI number (in case the Product is a mobile phone); </li>
                                </ol>
                            </li>

                            <li>Unboxing/normal video clip indicating the issues in the product/ shipment delivered. The video clip needs  to  capture  all  sides  of  the  package,  order  details,  shipping  label,  quantity  of  the  product, damages in the product, any tampering with the outer box etc. preferably with timestamp.</li>
                            <li>For  Consumer  Electronics:  It  is  mandatory  to  shoot  an  unboxing  video  for  both  the  buyer  and  the seller and the same must be submitted while raising a return request. No return will be entertained if an unboxing video is not provided.</li>
                            <li>Copy of the bill/ tax invoice for the product received.</li>
                            <li>Packing  video  of  the  package,  the  video  clip  needs  to  capture  all  sides  of  the  package,  order details, shipping label, quantity of the product packed in a box.

                                <p>&nbsp;</p>
                                <p><strong>Note: </strong>In  case  of  any  dispute  observed  for  Consumer  Electronics  whenever  return shipment  is  forwarded  to  Seller,  they  should  provide  initial  packing  video  as  well  as unboxing video upon receipt of any returned product from buyer.</p>
                            </li>
                        </ol>
                    </li>
                    <p>&nbsp;</p>
                    <p>If any further information or clarification is required from the Buyer, Solv team will contact them.</p>
                    <p>&nbsp;</p>
                    <li>
                        Following points are to be considered while placing return request:
                        <ol>
                            <li>Product shall be returned in the same brand packaging in which it was delivered.</li>
                            <li>In case a damaged outer packaging is received, the same should be mentioned as a remark on the proof of delivery&nbsp;</li>
                            <li>Products which are requested to be returned shall be stored separately so that no one can use them.</li>
                            <li>Ensure all products in a particular invoice are handed over to pickup partner as no partial return is permissible.</li>
                            <li>In case of an order for oil, same should be returned immediately if any damage/shortage is observed. Once accepted by buyer, it will not be considered for return.</li>
                            <li>Notwithstanding  anything  mentioned  in  the  return  policy, Return request for consumer electronic products will be accepted only in case of any of the following scenarios:&nbsp;
                                <ol style={{ "listStyleType": "lower-alpha" }}>
                                    <li>Disparity in the number of products/outer packaging ordered &amp; received.&nbsp;</li>
                                    <li>Outer packaging being damaged.</li>
                                    <li>Wrong product sent by the seller.</li></ol>
                            </li>
                            <p>&nbsp;</p>
                            <p><strong>Note</strong>: </p>
                            <p>
                                <ol style={{ "listStyleType": "lower-alpha" }}>
                                    <li>Under no circumstances will Solv bear any losses including those listed under 6 a,b,c. Solv will only coordinate with the seller.&nbsp;</li>
                                    <li>In case of no remarks mentioned on POD for damages return request due to product / outer packaging damaged will not be considered.</li>
                                </ol>
                            </p>
                        </ol>
                    </li>
                    <p>&nbsp;</p>
                    <li>
                        Return Requests shall not be processed in case of the following reasons:
                        <ol>
                            <li style={{ "text-align": "left;" }}>In case brand packaging is changed&nbsp;</li>
                            <li style={{ "text-align": "left;" }}>If there are no remarks on the proof of delivery about a damaged outer packaging</li>
                            <li style={{ "text-align": "left;" }}>Return request shall be processed for full return only, no partial return request is accepted</li>
                            <li style={{ "text-align": "left;" }}>Return requests will be rejected in case a product being returned is found to be used</li>
                            <li style={{ "text-align": "left;" }}>Return request for oil shall not be processed once the buyer accepts the shipment</li>
                            <li style={{ "text-align": "left;" }}>It is clarified that returns cannot be processed for the following reasons:
                                <ol style={{ "listStyleType": "lower-alpha" }}>
                                    <li>Customer did not like the product</li>
                                    <li>Product is available at better price elsewhere</li>
                                    <li>Customer does not need the product any more</li>
                                    <li>Any reason excluding disparity in the number of items ordered &amp; received, product defect or damage or incorrect material received.</li>
                                </ol>
                            </li>
                        </ol>
                        <p style={{ "marginTop": "2vw" }}>In any of the above cases, the return request will be rejected by the customer care team&nbsp;</p>
                    </li>
                    {/* <li>
                        At the time of making a return request on the platform, Buyer will be required to provide appropriate supporting documentations/ proofs as mentioned below:
                        <ol>
                            <li style={{ "text-align": "left;" }}>Images of the product(s) indicating the issue in the product/ shipment delivered. The images need
                                to capture the following:&nbsp;
                                <ol style={{ "listStyleType": "lower-alpha" }}>
                                    <li>issue observed by the buyer in the product;</li>
                                    <li>damages to the product; and/or</li>
                                    <li>the IMEI
                                        number (in case the Product is a mobile phone);</li>
                                </ol></li>

                            <li style={{ "text-align": "left;" }}>Unboxing/normal video clip indicating the issues in the product/ shipment delivered. The video clip
                                needs to capture all sides of the package, order details, shipping label, quantity of the product,
                                damages in the product, IMEI number (in case of mobile phones), any tampering with the outer box
                                etc.</li>
                            <li style={{ "text-align": "left;" }}>For Consumer Electronics, it is mandatory to shoot an unboxing video by the buyer and the same
                                must be submitted while raising a return request.</li>
                            <li style={{ "text-align": "left;" }}>Copy of the bill/ tax invoice for the product received.</li>
                        </ol>
                        <p style={{ "marginTop": "2vw" }}><strong>Note</strong>: In case of any dispute observed for Consumer Electronics whenever return case is forwarded to Seller, they should provide initial packing video as well as unboxing video upon receipt of any returned product from buyer.&nbsp;</p>
                        <p style={{ "marginTop": "2vw" }}>If any further information or clarification is required from the Buyer, we will contact them.&nbsp;</p>

                    </li> */}
                    <li>
                        Depending on the category of the product, the Buyer may raise a Return Request for any of the issues as set forth under paragraph A.2 above within the timelines prescribed below. In case the request is raised beyond this window, the return/ refund will not be done.
                    </li>

                    <table style={{ "width": "100%;", "marginTop": "2vw" }}>
                        <thead>
                            <tr>
                                <th>Category&nbsp;<br></br></th>
                                <th>Timelines&nbsp;<br></br></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ "width": "50.0000%;" }}>FMCG*&nbsp;<br></br></td>
                                <td style={{ "width": "50.0000%;" }}>Delivery Date +1&nbsp;<br></br></td>
                            </tr>
                            <tr>
                                <td style={{ "width": "50.0000%;" }}>HoReCa*&nbsp;<br></br></td>
                                <td style={{ "width": "50.0000%;" }}>Delivery Date +1&nbsp;<br></br></td>
                            </tr>
                            <tr>
                                <td style={{ "width": "50.0000%;" }}>Apparel&nbsp;<br></br></td>
                                <td style={{ "width": "50.0000%;" }}>Delivery Date +2<br></br></td>
                            </tr>
                            <tr>
                                <td style={{ "width": "50.0000%;" }}>Consumer Electronics&nbsp;<br></br></td>
                                <td style={{ "width": "50.0000%;" }}>Delivery Date +2&nbsp;<br></br></td>
                            </tr>
                            <tr>
                                <td style={{ "width": "50.0000%;" }}>Home Furnishing&nbsp;<br></br></td>
                                <td style={{ "width": "50.0000%;" }}>Delivery Date +2</td>
                            </tr>
                            <tr>
                                <td style={{ "width": "50.0000%;" }}>Footwear&nbsp;<br></br></td>
                                <td style={{ "width": "50.0000%;" }}>Delivery Date +2</td>
                            </tr>
                            <tr>
                                <td style={{ "width": "50.0000%;" }}>Toys & Sports&nbsp;<br></br></td>
                                <td style={{ "width": "50.0000%;" }}>Delivery Date +2</td>
                            </tr>
                        </tbody>
                    </table>
                    <p style={{ "marginTop": "2vw" }}>* Except oil products which need to be returned on the same day, immediately upon delivery</p>
                    <p style={{ "marginTop": "2vw" }}>Solv team internally scrutinize the return request raised by the Buyer and notify the Seller. The Return Request raised by  the Buyer will either be approved or rejected and the same will be communicated to the Buyer.</p>
                </ol>




                <p><strong>B. DECISION ON THE RETURN REQUESTS</strong></p>
                <p>Basis our internal scrutiny of the Return Request raised by the Buyer, any of the following may occur:
                </p>
                <ol>
                    <li><strong>Approval of the Return Request</strong>:The Return Request will be processed under following scenarios:
                        
                        <ol style={{ "listStyleType": "lower-alpha" }}>
                            <li>The return request has been approved by the Seller. In such a case, the reverse shipment will be shipped to the seller. In case seller fails to respond within 1 day from the time of escalation same shall be considered as approved from seller for return. </li>
                            <p>or</p>
                            <li> If we determine that the Return Request is a Logistics Related Issue and Seller is not ready to accept return request, in such case, 
                                <ol style={{ "listStyleType": "lower-roman" }}>
                                    <li>We will pay the full value (as declared) of the Shipment to the Seller; and</li>
                                    <li>We shall have the right to proceed with the liquidation of such Reverse Shipment. In such cases, the Seller  shall  have  no  objection  for  carrying  out  liquidation  and  shall  waive  any  claim  in  this  regard.  All rights, title, or interest on all such Products being liquidated shall be deemed to be transferred by the Seller so that liquidation can be carried out by Solv or its logistic partners.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>To ensure successful return pick up of the Shipment, the Buyer is required to return the complete Shipment in the original condition and in the same bag as it was received in.</li>
                    <li>In case of approval of the return request if the buyer has already paid for the order, the order amount will be refunded to the buyer’s payment source (Bank Account as updated on the Solv App / Credit Card, Etc.) within 15 days for intracity return requests and 45 days for intercity return requests, from the date of the return request being raised along with all required documentations.
                        <p style={{ "text-align": "left;", "marginTop": "2vw" }}><strong>Note</strong>:
                        In case the buyer has utilized Pay Later services, the amount shall be refunded to the Financial Institution, and it will be adjusted with the current outstanding amount before the due date and if the refund happens after the due date, the amount shall be marked as excess and will be adjusted during next billing.&nbsp;
                        </p></li>
                    <p>&nbsp;</p>
                    <li> <strong>
                        Rejection of the Return Request
                    </strong>: The Return Request raised by the Buyer shall be rejected, in case of any of
                        the following reasons:
                        <ol>
                            <li>
                                If the Buyer does not respond to the inquiry calls and/or communication via other modes made by
                                us to procure missing documentation and information in relation to the Return Request; or
                            </li>
                            <li>
                                supportive documents are insufficient, or Buyer is unable to provide sufficient proof in support of
                                the claim.
                            </li>
                            <li>
                                If the Buyer’s return history shows excessive returns, then the Return Request of the Buyer may be rejected.
                            </li>
                        </ol>

                    </li>
                    <li><strong>
                        Exceptional cases
                    </strong>: In exceptional cases such as fraud, deficiency in service, or any other circumstance which may affect the user experience on the Platform, we may in good faith and in our sole discretion decide to pay the transaction amount for the disputed product in the absence of Seller's approval of such returns.
                    <p>&nbsp;</p>
                    The said payment of transaction amount shall be made to compensate for the inappropriate user experience. In such exceptional cases, payment will be initiated to the Buyer within Seventy-Two (72) banking hours from:
                    <p>&nbsp;</p>
                    <ol style={{listStyleType: 'lower-roman'}}>
                        <li>
                        the date of approval of the Return Request by us; or
                        </li>
                        <li>
                        from the date Buyer confirms the dispatch of the Product from Buyer's premise/ shop/ designated pick-up location; whichever is later.
                        </li>
                    </ol>
                    </li>
                    <li>
                    In all the above-mentioned cases wherein, the return requests are approved by the Seller, refund will be initiated to the Buyer post the product pickup from the buyer & delivery to seller.
                    </li>

                </ol>

                <p><strong>C. DELIVERY OF REVERSE SHIPMENT TO THE SELLER</strong></p>

                <ol>
                    <li>Once  the  Return  Request  raised  by  the  Buyer  is  accepted,  our  logistics  partner  will  pick  up  the  Shipment from  the  Buyer  ("Reverse  Shipment")  within  such  timelines,  as  maybe  communicated  or  displayed  on  the Platform.</li>
                    <li>The  Reverse  Shipment  will  be  delivered  to  the  Seller  by  us  (at  the  location  from  where  the  Shipment  was picked up for forward order delivery to the Buyer). We will endeavour to make the first attempt to deliver the Reverse Shipment to the Seller within:
                        <ol style={{ "listStyleType": "lower-roman" }}>
                            <li>thirty-five  (35)  days  from  the  date  of  receipt  of  Reverse  Shipment  (non-food  products)  from  the Buyer; and</li>
                            <li>two  to  five  (2-5)  days  from  the  date  of  receipt  of  Reverse  Shipment  (Food  and  FMCG  category) from the Buyer.</li>
                        </ol>
                    </li>
                    <li>For  any  delay  in  making  the  first  attempt  to  deliver  the  Reverse  Shipment  beyond  the  said  timeline  due  to reasons solely attributable to us, we shall communicate such delay to the Seller within a reasonable time. In case  of  unavoidable  circumstances  or  occurrence  of  a  force  majeure  event  or  any  event  beyond  our reasonable control, timelines shall stand extended. The Seller will be able to track the Reverse Shipment on the  Platform.  The  Seller  will  be  bound  to  accept  the  delivery  of  Reverse  Shipment  and  sign  the  proof  of delivery.</li>
                    <li>If  we  are  unable  to  deliver  the  Reverse  Shipment  to  the  Seller  due  to  Seller's  or  its  representative's unavailability at  the delivery  location  on  the  first  attempt, our logistics partner will  make  a maximum of  two (2)  more  attempts  to  deliver  the  Reverse  Shipment.  We  will  endeavour  to  attempt  delivery  within  the  next forty-eight  (48)  hours  from  the  last  delivery  attempt.  Our  returns  team  will  contact  the  Seller  to  arrange  for delivery  of  the  Reverse  Shipment.  If  despite  our  reasonable  efforts,  we  are  unable  to  deliver  the  Reverse Shipment to the Seller in the third  attempt,  the Reverse Shipment  will  be transferred to our  liquidation hub for liquidation. In such cases, the Seller shall have no objection for carrying out liquidation and shall waive any claim in this regard. All rights, title, or interest on all such Products being liquidated shall be deemed to be transferred by the Seller so that liquidation can be carried out by Solv or its logistic partners. The Seller agrees  that  the  Seller  shall  not  be  entitled  to  receive  any  money  or  amount  that  we  or  our  logistic  partner may receive from liquidation of such Reverse Shipment.</li>
                    <li>We  will  return  the  Reverse  Shipment  to  the  Seller  on  an  'as  is'  basis  i.e.,  in  the  same  condition  as  it  is received  by  us  from  the  Buyer.  At  the  time  of  delivery  of  the  Reverse  Shipment  to  the  Seller,  the  Seller further  acknowledges  the  POD  and  ensure  that  photos,  videos,  etc.  and/or  remarks  with  respect  to  the Reverse  Shipment  along  with  the  AWB  number  affixed  on  it  ("Evidence")  shall  be  captured  and  used  by seller  at  the  time  of  settling  disputes  (if  any)  that  may  be  raised  by  the  Seller  with  respect  to  the  Reverse Shipment. We may ask the Seller to provide or submit additional documents in support of the dispute raised as per the terms of this Policy.</li>
                    <li>At  the  time  of  delivery  of  the  Reverse  Shipment  to  the  Seller  or  any  of  the  Seller's  representatives,  our logistics  partner  will  require  the  Seller  to  acknowledge  the  delivery  of  the  same  by  signing  on  the  proof  of delivery  document  ("POD").  Seller  or  its  representative  is  required  to  sign  on  the  POD  and  clearly  call  out the  damage  or  tampering  to  the  Reverse  Shipment,  In  case  of  logistics  partner  is  not  allowing  seller  to mention  remarks  in  that  situation  seller  may  refuse  to  accept  &  raise  a  complaint  on  Solv  customer  care number.</li>
                    <li>At the time of receiving the Reverse Shipment, the Seller or any of the Seller's representatives shall observe the following:
                        <ol style={{ "listStyleType": "lower-roman" }}>
                            <li>Shall not detain our Delivery Associate for any reason whatsoever;</li>
                            <li>any  tampering,  pilferage,  or  damage  to  the  Reverse  Shipment  at  the  time  of  receiving  the Reverse Shipment from us shall be written on the POD and the same shall be reported to us within  2  days  from  the  receipt  of  delivery  of  Reverse  Shipment  either  by  calling  us  on  our customer care number or also can send an E-mail at cs@solvezy.com. If the Seller does not write  on  the  POD  about  any  damage,  tampering  or  pilferage  to  the  Reserve  Shipment,  we reserve the right to reject any Dispute which the Seller may raise against us with respect to the damage to the Reverse Shipment during reverse transit of the Shipment.</li>
                        </ol>
                    </li>
                    <li>The Seller is bound to accept the delivery of Reverse Shipment. However, despite our reasonable efforts to deliver the Reverse Shipment, if the Seller refuse to take delivery of the Reverse Shipment, we reserve our right  to  move  the  Reverse  Shipment  to  our  liquidation  hub  and  liquidate  or  dispose  off  such  Reverse Shipment in any manner as we may deem fit in our sole discretion. We reserve a right to deduct and adjust the value of the Reverse Shipment (in case the amount for such Shipment has already been transferred to the Seller) against the sale proceeds that are collected by us on Seller's behalf from the Buyer or any other amounts that we are required to pay to the Seller in accordance with the Payment and Settlement Services. We shall not entertain any claims brought with respect to such unaccepted / refused Reverse Shipment and the Seller agrees that the Seller shall not be entitled to receive any money or amount that we or our logistics may receive from liquidation of such Reverse Shipment. In such cases, the Seller shall have no objection for carrying  out  liquidation  and  shall  waive  any  claim  in  this  regard.  All  rights,  title,  or  interest  on  all  such Products being liquidated shall be deemed to be transferred by the Seller so that liquidation can be carried out by Solv or its logistic partners.</li>
                    <li>If  it  is  found  that  the  Seller  has  misbehaved  with  our  customer  support  team  or  forcefully  detained  or misbehaved with our SO/RM, we reserve the right to not deliver the Reverse Shipment and deduct & adjust the  value  of  the  Reverse  Shipment  from  the  Seller  sale  proceeds.  We  further  reserve  the  right  to  take appropriate action against the Seller as per applicable laws and/or as per Solv's Terms of Use.</li>
                </ol>

                <p><strong>D. DISPUTES RAISED BY THE SELLER</strong></p>

                <ol>
                    <li>Upon  receipt  of  Reverse  Shipment  from  the  Buyer,  Seller  can  raise  a  dispute  with  respect  to  the  Reverse Shipment  within  2  working  days  from  receipt  of  the  same,  failing  which  it  shall  be  deemed  as  accepted  by the Seller without any damage or fault. The Seller may raise a dispute for any one of the following reasons using the Platform:
                        <ol style={{ "listStyleType": "lower-roman" }}>
                            <li>Reverse Shipment/outer box is in a visibly damaged condition or tampered and the same has been mentioned on POD;</li>
                            <li>Products received are wrong;</li>
                            <li>Difference in the quantity;</li>
                            <li>If  the  Reverse  Shipment  is  not  delivered  to  Seller  but  marked  "delivered"  on  the  Platform; and/or</li>
                            <li>Mismatch/non-alignment with the approved refund amount.</li>
                        </ol>
                        The Seller will also have a right to raise a dispute in cases where we choose to settle the amount with Buyer for the returned product without sending the returned product to the Seller.
                    </li>
                    <li>Upon  receipt  of  the  Seller(s)  complaint  or  dispute,  our  respective  dispute  resolution  team  will  get  in  touch with the Seller to resolve concerns or disputes in accordance with our Policy, as maybe communicated from time to time. The Seller at the time of raising the dispute will be required to provide us photographs and/or videos of Reverse Shipment (internal and outer box), and such other documents/ proof or information, which we may require to investigate into the issue or concern raised. The Seller agrees to co-operate with us while we investigate the claim or dispute raised.</li>
                    <li>For the purpose of investigating the claim, We may require certain additional documents/ proof/ information in support of the claim at the time of raising a dispute or post submission of the dispute by the Seller. Seller will be required to provide such additional documents/ proof/information in support of the claim within 2 days from the request raised for such document/ proof/information, which will include without limitation, images of the returned product(s) indicating the issue in the shipment received. The images need to clearly capture the following:
                        <ol style={{ "listStyleType": "lower-roman" }}>
                            <li>Shipment label;</li>
                            <li>AWB number of the shipment;</li>
                            <li>issue observed by Seller in the returned product;</li>
                            <li>damages to the returned product;</li>
                            <li>brand name/ manufacturer's name of the returned product; and/or</li>
                            <li>the IMEI number (in case the product is a mobile phone).</li>
                        </ol>
                        The Seller further agrees that Solv’s decision with respect to the investigation shall be final and binding.
                    </li>
                    <li>Upon receipt of the seller(s) dispute and necessary documentation in support of the claim, we will investigate internally and endeavor to resolve the dispute within fifteen (15) days of receipt of dispute.</li>
                    <li>Based on the documentation provided by the Seller, the dispute may be resolved either:
                        <ol style={{ "listStyleType": "lower-roman" }}>
                            <li>against the Seller, if it is concluded by us that:
                                <ol style={{ "listStyleType": "lower-alpha" }}>
                                    <li>evidences collected by us and documentation, proof, and information provided by Seller in support of Seller's claim do not match;</li>
                                    <li>document/  proof  provided  by  Seller  in  support  of  Seller's  claim  are  insufficient;  or  Seller does not respond to the inquiry calls and/or communication through other modes sent by us  to  procure  missing  documentation,  proof,  and  information  in  support  of  Seller's  claim from the Seller; in such cases Seller's dispute will be settled against the Seller.</li>
                                </ol>
                            </li>
                            In such cases, within 3 days of resolution of Seller's dispute, we shall deduct and adjust the value of the returned product (in case the amount for such Shipment has already been transferred to the Seller) from Seller's sale proceeds that are collected by us from the Buyer(s) or any other amounts that we are required to pay to the Seller; or
                            <li>
                            in Seller's favour, if it is concluded by us that document/ proof/ information submitted by Seller along  with  Seller's  dispute  are  sufficient  to  justify  Seller's  claim  and  match  with  Evidences collected  by  us,  then  we  will  approve  Seller's  dispute.  The  amount  that  the  Seller  will  be eligible  to  receive  in  relation  to  such  approved  claims  shall  be  calculated  on  the  basis  of  the category of the Product for which the return is raised. The applicable amount to be refunded to the  Seller  shall  be  as  mentioned  within  3  working  days,  communicated  through  such  other mode of communication as may be determined by us in our sole discretion.
                            </li>
                            
                        </ol>
                        
                        <p>&nbsp;</p>
                        <p>The  Seller  further  agrees  that  Solv’s  decision  with  respect  to  the  investigation  shall  be  final  and binding.</p>
                        <p>&nbsp;</p>
                        <p>Seller understands that Solv is an intermediary platform and only be responsible to mediate reverse shipment  issue  raised  by  Seller.  It  shall  be  sole  responsibility  of  the  Seller  to  resolve  the  issues/ concerns  raised  by  Seller  with  the  Buyer.  We  shall  not  assume  any  liability  for  any  failure  on  the part of the Buyer to resolve any reverse shipment issue.</p>
                        <p>&nbsp;</p>
                        <p>The  raising  of  a  Return  Request  and  dispute  resolution  is  a  mechanism  of  User  protection  and  is subjected to guidelines of fair use. If we in our opinion become suspicious or become aware of any misuse of this Policy or the Platform by any User, we may change the applicability of our Policy.</p>
                        <p>&nbsp;</p>
                        <p>SCR&T  reserves  the  right  at  any  time,  at  its  sole  discretion,  to  change  or  otherwise  modify  this return  policy  without  prior  notice.  The  User  agrees  to  be  bound  to  any  changes  or  modifications made to this Policy as may be updated on the Platform, from time to time or any additional terms that may be communicated to the User, from time to time. Continued access or use of Solv platform signifies the assent/ratification of the updated or modified policy.</p>
                    </li>
                </ol>



            </div>
        </>
    )
}

export default ReturnShipmentPolicy
